import axios from 'axios';
import { MessageBox } from 'element-ui';
import store from '../store/index';
import router from '../router/index';
import { message } from '../rewrite/message';
const baseURL = "http://www.chiffoele.com:9101/GasSafetySystem";
// const baseURL = "http://10.10.20.88:44210/GasSafetySystem";

// const baseURL = "http://10.10.10.112:8085/LampblackMonitoring";
export const interceptor = axios.create({
	baseURL,
	timeout: 10000
});
//请求拦截
interceptor.interceptors.request.use(
	config => {
		config.xhrFields = {
			withCredentials: true
		}
		if( config.is_token ){
			config.headers.Authorization = store.state.token;
		}
		config.crossDomain = true;
		return config
	},
	error => {
		return error
	}
);
//响应拦截
interceptor.interceptors.response.use(
	response => {
		if( response.data.code == 401 ){
			message({
				message : response.data.message,
				type: 'error',
				duration: 5 * 1000,
			});
			router.replace({ path : '/login' });
			store.commit('$uStore', {
				name: 'user_info',
				value: ''
			})
			return response.data;
		}
		return response.data;
	},
	error => {
		let errorMessage = "";
		if( error.response.config.responseType == "blob"  ){
			errorMessage = "根据条件查询无数据";
		}else{
			errorMessage = error.response.data ? error.response.data.message : "请求失败";
		}
		message({
			message : errorMessage,
			type: 'error',
			duration: 5 * 1000
		});
		return Promise.reject( error )
	}
);
const install = ( Vue , vm ) => {
	Vue.prototype.$interceptor = interceptor;
	Vue.prototype.$baseURL = baseURL;
}
export default {
	install
}